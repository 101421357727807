define("nfe-customer-console-v2/mirage/config", ["exports", "ember-cli-mirage", "ember-file-upload/mirage"], function (_exports, _emberCliMirage, _mirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    /*
      Config (with defaults).
         Note: these only affect routes defined *after* them!
    */
    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    this.namespace = ''; // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    const formEncodedToJson = encoded => {
      let result = {};
      encoded.split("&").forEach(part => {
        let item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    };

    this.post('/token', (schema, request) => {
      let params = formEncodedToJson(request.requestBody);

      if (params.username === "teste@teste.com" && params.password === "mudar1234") {
        return {
          "access_token": "PA$$WORD",
          "token_type": "bearer",
          "name": "teste@teste.com",
          "userName": "teste@teste.com",
          "roles": "customer"
        };
      } else {
        return new _emberCliMirage.Response(401, {}, {
          errors: 'Email or password is invalid'
        });
      }
    });
    this.post('/users', (schema, request) => {});
    this.post('/leads', (schema, request) => {}); //here

    this.post('/accounts', (schema, request) => {
      let params = JSON.parse(request.requestBody);

      if (params.name == "teste" && params.email == "teste@teste.com" && params.phoneNumber == "+55 11 99999-9999") {
        return {
          // "access_token": "PA$$WORD",
          // "token_type": "bearer",
          "name": "teste",
          "userName": "teste@teste.com",
          "phoneNumber": "+551199999-9999"
        };
      } else {
        return new _emberCliMirage.Response(401, {}, {
          errors: 'Caca ao criar conta'
        });
      }

      const paramsFin = params;
    });
    this.get('/accounts', (schema, request) => {});
    this.get('/accounts/:accountId/providers');
    this.post('/accounts/:accountId/providers/:provider/register', ({
      providers
    }, request) => {
      let provider = providers.findBy({
        namespace: request.params.provider
      });
      provider.attrs.status = 'Active';
      provider.update(provider);
    });
    this.post('/accounts/:accountId/providers/:provider/unregister', () => {});
    this.get('/accounts/:accountId/apikeys', () => {
      return {
        "apiKeys": [{
          "parentId": "54244a1be340420fdc94ab59",
          "id": "5EThQZhp3Uto6JoeDWiIRjmjrGug8fi4k2b1R9gLUQ0Raexd0WmI5PrnWdJU9rO",
          "description": "Dados (open.nfe.io)",
          "createdOn": "2019-02-20T15:00:02.7166629+00:00",
          "modifiedOn": "2018-09-19T20:06:58.0863057+00:00",
          "status": "Active"
        }, {
          "parentId": "54244a1be340420fdc94ab59",
          "id": "sd2rM9ssEUMKbTWBuP5kKZq8gSSwGEsVwBMKVsj7xWpaZamq43vwCxWaEHeYNqu",
          "description": "Nota Fiscal (api.nfe.io)",
          "createdOn": "2019-02-20T15:00:02.7166629+00:00",
          "modifiedOn": "2018-08-02T19:08:52.399209+00:00",
          "status": "Active"
        }, {
          "parentId": "54244a1be340420fdc94ab59",
          "id": "yucG1xbixd2SxjihAwmTKnx7z9pmD9NwfMYxzCVWDx3G999NAkC6LpBnqqWQoXScMWl",
          "description": "Nota Fiscal (api.nfe.io)",
          "createdOn": "2014-09-25T17:00:11+00:00",
          "modifiedOn": "2016-09-08T00:29:39.4816084+00:00",
          "status": "Active"
        }]
      };
    });
    this.get('/accounts/:accountId/reports/service_invoices/:year', ({
      metrics
    }, request) => {
      return metrics.all();
    });
    this.get('/accounts/:accountId', () => {
      return {
        "accounts": {
          "id": "54244a1be340420fdc94ab59",
          "createdOn": "2014-09-25T17:00:11.395506+00:00",
          "modifiedOn": "2019-01-31T16:50:50.4936134+00:00",
          "name": "Infra",
          "federalTaxNumber": 0,
          "email": "infra@nfe.io",
          "address": {
            "country": "",
            "postalCode": "05416-011",
            "street": "Rua Fradique Coutinho",
            "number": "701",
            "additionalInformation": "",
            "district": "Pinheiros",
            "city": {
              "code": "3550308",
              "name": "São Paulo"
            },
            "state": "SP"
          },
          "paymentMethods": [],
          "status": "Active",
          "environment": "Development"
        }
      };
    }); // this.put('/accounts/:accountId', (schema, request) => {
    //   return JSON.parse(request.requestBody);
    // });
    // this.get('/accounts/:accountId/reports/service_invoices/:year', (schema, request) => {
    //   let year = request.params.year;
    //   return schema.db.serviceInvoices.filter(item => item.meta.year == year)[0];
    // })

    this.get('/accounts/:accountId/hooks'); // this.post('/accounts/:accountId/hooks', (schema, request) => {
    //   let params = JSON.parse(request.requestBody);
    //   return schema.hooks.create(params);
    // });
    // this.get('/accounts/:accountId/hooks/:hookId', () => {
    //   return {
    //     "hooks": {
    //       "id": 15758901347770,
    //       "url": "https://prod-37.westus.logic.azure.com:443/workflows/bd597e0707364b0caaf1a813ba684c94/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WEMr1YSwbSC9BzexVeiRv3q9pW15JplFxlbDNHm2H54",
    //       "contentType": "application/json",
    //       "secret": "mudar1234",
    //       "events": [
    //         "issue",
    //         "cancel",
    //         "pull"
    //       ],
    //       "status": "Active",
    //       "createdOn": "2018-10-25T14:28:53.5072407+00:00",
    //       "modifiedOn": "2018-12-14T22:13:50.0548012+00:00"
    //     }
    //   }
    // })
    // this.put('/accounts/:accountId/hooks/:hookId', (schema, request) => {
    //   return JSON.parse(request.requestBody);
    // });

    this.get('/accounts/:accountId/companies/', ({
      companies
    }, request) => {
      if (request.queryParams.search && request.queryParams.search == 'teste') {
        return companies.where({
          name: 'Teste'
        });
      }

      return companies.all();
    });
    this.post('/accounts/:accountId/companies', (schema, request) => {
      let params = JSON.parse(request.requestBody);
      params.id = Math.floor(Math.random() * 10000);
      params.environment = 'Development';
      params.fiscalStatus = 'Pending';
      params.certificate = {
        status: 'Pending'
      };
      params.createdOn = new Date();
      params.modifiedOn = new Date();
      schema.companies.create(params);
      return new _emberCliMirage.Response(201, {}, {
        companies: params
      });
    });
    this.get('/accounts/:accountId/companies/:companyId', ({
      companies
    }, request) => {
      return {
        companies: companies.find(request.params.companyId).attrs
      };
    });
    this.get('/accounts/:accountId/companies/:companyId/serviceInvoices', ({
      serviceInvoices
    }, request) => {
      let comparebleStatus = request.queryParams.status.charAt(0).toUpperCase() + request.queryParams.status.slice(1).toLowerCase();

      if (comparebleStatus == 'None') {
        return serviceInvoices.all();
      }

      return serviceInvoices.where({
        status: comparebleStatus
      });
    });
    this.put('/accounts/:accountId/companies/:companyId', ({
      companies
    }, request) => {
      return companies.find(request.params.companyId).update(JSON.parse(request.requestBody));
    });
    this.post('/accounts/:accountId/companies/:companyId/certificate', (0, _mirage.upload)(({
      companies
    }, request) => {
      if (request.requestBody.password == '1234') {
        return companies.find(1).update('certificate', {
          expiresOn: "2019-09-03T11:15:34+00:00",
          modifiedOn: new Date(),
          status: "Active",
          thumbprint: "1D1827FFF2D63EF4F1E1BFC657425D4FA396B78F"
        });
      } else {
        return new _emberCliMirage.Response(400, {}, {
          message: 'certificate password is not valid'
        });
      }
    }));
    this.post('/accounts/:accountId/companies/:companyId/environment', ({
      accounts,
      companies
    }, request) => {
      let account = accounts.first();
      let company = companies.find(request.params.companyId);

      if (account.environment == 'Development') {
        return new _emberCliMirage.Response(400, {}, {
          message: "account is not on production enviroment"
        });
      }

      return company.update(JSON.parse(request.requestBody));
    });
    this.del('/companies/:accountId/serviceinvoices/:id', ({
      serviceInvoices
    }, request) => {
      return serviceInvoices.where({
        id: request.params.id
      });
    });
    this.post('/companies/:id/serviceinvoices', (schema, request) => {
      return new _emberCliMirage.Response(202);
    });
    this.get('/accounts/:accountId/batch-processes', schema => {
      return schema.batches.all();
    });
    this.post('/accounts/:accountId/batch-processes', ({
      batches
    }, request) => {
      let body = JSON.parse(request.requestBody).batch;
      let data = {
        accountId: body.accountId,
        name: body.name,
        resource: body.resource,
        flowStatus: "Pending",
        status: "Active",
        totalLines: 0,
        totalOkLines: 0,
        totalErrorLines: 0,
        modifiedOn: new Date(),
        createdOn: new Date(),
        inputBlob: body.inputBlob
      }; // let xmlDescription = {
      //     status: "XmlCompleted",
      //     totalOKLines: 2,
      //     totalErrorLines: 0,
      //     modifiedOn: "2019-07-01T13:59:50.4546155-03:00",
      //     fileBlob: {
      //         name: "sefaz-nfe-export-sefaz_nfe_4a1b5361970748f3a2db31f37b8723a4-xml.zip",
      //         contentType: "application/zip",
      //         url: "https://devnfsetable.blob.core.windows.net/export/sefaz-nfe-export-sefaz_nfe_73b499eae2c24f43bccb9a7f364d9e97-xml.zip?sv=2018-03-28&sr=b&sig=0vOZsmOkGnMZ47rcU2MVIA%2FxvvGgIRjcARATrXpqI9Q%3D&st=2019-07-02T17%3A49%3A57Z&se=2020-07-03T17%3A49%3A57Z&sp=rl"
      //     }
      // };
      // let pdfDescription = {
      //     status: "PdfCompleted",
      //     totalOKLines: 2,
      //     totalErrorLines: 0,
      //     modifiedOn: "2019-07-01T13:59:50.4546155-03:00",
      //     fileBlob: {
      //         name: "sefaz-nfe-export-sefaz_nfe_4a1b5361970748f3a2db31f37b8723a4-pdf.zip",
      //         contentType: "application/zip",
      //         url: "https://devnfsetable.blob.core.windows.net/export/sefaz-nfe-export-sefaz_nfe_73b499eae2c24f43bccb9a7f364d9e97-pdf.zip?sv=2018-03-28&sr=b&sig=x6RGle0yN94Yx14JarexmkiAB8yCmyLwJmb%2F%2Fzh%2BQfQ%3D&st=2019-07-02T17%3A50%3A18Z&se=2020-07-03T17%3A50%3A18Z&sp=rl"
      //     }
      // };
      // if (body.type != "pdf")
      //     data.xmlDescription = xmlDescription;
      // if (body.type != "xml")
      //     data.pdfDescription = pdfDescription;

      let batch = batches.create(data);
      return new _emberCliMirage.Response(201, {}, batch);
    }); // -------------- Product Invoice Back-end --------------------
    // Companies

    this.get('/v2/companies/', function (schema, request) {
      let hasMore = true;
      let queryParams = request.queryParams;
      let companies = this.serialize(schema.companyV2s.all()).companyV2s;
      let response;

      if (queryParams.startingAfter) {
        let start = companies.findIndex(element => element.id === queryParams.startingAfter);
        companies = companies.slice(start + 1);
        hasMore = companies.length > queryParams.limit ? true : false;
        response = companies.slice(0, queryParams.limit);
      } else if (queryParams.endingBefore) {
        let endingBefore = companies.findIndex(element => element.id === queryParams.endingBefore);
        response = companies.slice(endingBefore - queryParams.limit, endingBefore);
      } else {
        response = companies.slice(0, queryParams.limit < companies.length ? queryParams.limit : companies.length - 1);
      }

      return {
        companies: response,
        hasMore
      };
    });
    this.post('/v2/companies/', ({
      companyV2s
    }, request) => {
      let body = JSON.parse(request.requestBody).company;
      let company = companyV2s.create(body);

      if (company.federalTaxNumber == 191) {
        return new _emberCliMirage.Response(400, {}, {
          "errors": [{
            "code": 400,
            "message": "Algum parametro informado não é válido"
          }]
        });
      } else {
        return {
          company: company.attrs
        };
      }
    });
    this.get('/v2/companies/:companyId', ({
      companyV2s
    }, request) => {
      return {
        company: companyV2s.find(request.params.companyId).attrs
      };
    });
    this.put('/v2/companies/:companyId', ({
      companyV2s
    }, request) => {
      let requestData = JSON.parse(request.requestBody);
      return companyV2s.find(request.params.companyId).update(requestData.company);
    }); // Companies State Taxes

    this.get('/v2/companies/:companyId/stateTaxes', function (schema, request) {
      return schema.stateTaxes.all(); // STATE TAXES PAGINATION MOCK
      // let stateTaxes = schema.stateTaxes.all();
      // let response = { hasMore: true, stateTaxes: null };
      // let queryParams = request.queryParams;
      // let stateTaxes = this.serialize(schema.stateTaxes.all()).stateTaxes;
      // if (queryParams.startingAfter) {
      //     let start = stateTaxes.findIndex((element) => element.id === queryParams.startingAfter);
      //     stateTaxes = stateTaxes.slice(start + 1);
      //     response.hasMore = stateTaxes.length > queryParams.limit ? true : false;
      //     response.stateTaxes = stateTaxes.slice(0, queryParams.limit);
      // } else if (queryParams.endingBefore) {
      //     let endingBefore = stateTaxes.findIndex((element) => element.id === queryParams.endingBefore);
      //     response.stateTaxes = stateTaxes.slice(endingBefore - queryParams.limit, endingBefore);
      // } else {
      //     response.stateTaxes = stateTaxes.slice(0, queryParams.limit < stateTaxes.length ? queryParams.limit : stateTaxes.length);
      // }
      // return response;
    });
    this.get('/v2/companies/:companyId/statetaxes/:statetaxId', ({
      stateTaxes
    }, request) => {
      let stateTax = stateTaxes.find(request.params.statetaxId).attrs;
      stateTax.companyId = request.params.companyId;
      return {
        stateTax
      };
    });
    this.post('/v2/companies/:companyId/statetaxes', ({
      stateTaxes
    }, request) => {
      let body = JSON.parse(request.requestBody).stateTax;
      body.environmentType = "Test";
      body.status = "Active";
      body.series.push(body.serie);

      if (body.taxNumber == 123123) {
        let stateTax = stateTaxes.create(body);
        return {
          stateTax: stateTax.attrs
        };
      } else {
        return new _emberCliMirage.Response(400, {}, {
          "errors": [{
            "code": 400,
            "message": "Algum parametro informado não é válido"
          }]
        });
      }
    });
    this.put('/v2/companies/:companyId/statetaxes/:stateTaxId', ({
      stateTaxes
    }, request) => {
      let requestData = JSON.parse(request.requestBody);
      return stateTaxes.find(request.params.stateTaxId).update(requestData.stateTax);
    }); // Companies certificates

    this.post('/v2/companies/:companyId/certificates', ({
      certificateV2s
    }, request) => {
      let password = request.requestBody.get("password");
      let fileName = request.requestBody.get("file").name;

      if (password != "1234") {
        return new _emberCliMirage.Response(400, {}, {
          "errors": [{
            "code": 400,
            "message": "certificate password is not valid"
          }]
        });
      } else if (fileName == "certificado_vencido.pfx") {
        return new _emberCliMirage.Response(400, {}, {
          "errors": [{
            "code": 400,
            "message": "certificate is no longer valid after 9/3/2019 11:15:34 AM"
          }]
        });
      } else {
        const currentDate = new Date();
        const validUntil = new Date().setFullYear(currentDate.getFullYear() + 1);
        const newData = {
          federalTaxNumber: "15515900000149",
          modifiedOn: currentDate,
          status: "Active",
          subject: "CN=certificateName",
          thumbprint: "F1D1A06E32EDC63F36FE1AD7EAC8B05B592B5472",
          validUntil: new Date(validUntil)
        };
        let certificate = certificateV2s.first();
        return {
          certificate: Ember.isEmpty(certificate) ? certificateV2s.create(newData).attrs : certificate.update(newData).attrs
        };
      }
    });
    this.get('/v2/companies/:companyId/certificates', function (schema, request) {
      let response;
      let digitalCert = this.serialize(schema.certificateV2s.all()).certificateV2s;

      if (Ember.isEmpty(digitalCert)) {
        response = new _emberCliMirage.Response(400, {}, {
          "errors": [{
            "code": 40401,
            "description": "Certificate not found"
          }]
        });
      } else {
        response = {
          certificateV2s: digitalCert
        };
      }

      return response;
    }, {
      timing: 1000
    }); //get one product-invoice

    this.get('/v2/companies/:companyId/product-invoices/:invoiceId', ({
      productInvoices
    }, request) => {
      return productInvoices.find(request.params.invoiceId).attrs;
    }); // Webhooks

    this.get('/v2/webhooks/', function ({
      hookV2s
    }, request) {
      let webHooks = this.serialize(hookV2s.all()).hookV2s;
      return {
        webHooks
      };
    });
    this.post('/v2/webhooks/', function ({
      hookV2s
    }, request) {
      let body = JSON.parse(request.requestBody).hookV2;
      let webhook = hookV2s.create(body);
      return {
        webHook: webhook.attrs
      };
    });
    this.get('/v2/webhooks/:hookId', function ({
      hookV2s
    }, request) {
      let webHook = hookV2s.find(request.params.hookId).attrs;
      return {
        webHook
      };
    });
    this.put('/v2/webhooks/:hookId', ({
      hookV2s
    }, request) => {
      let requestData = JSON.parse(request.requestBody);
      return hookV2s.find(request.params.hookId).update(requestData.hookV2);
    });
    this.del('/v2/webhooks/:hookId', ({
      hookV2s
    }, request) => {
      return hookV2s.find(request.params.hookId).destroy();
    }); // Product Invoices

    this.get('/v2/companies/:companyId/productinvoices', function (schema, request) {
      const allInvoices = this.serialize(schema.productInvoices.all()).productInvoices;
      return {
        productInvoices: allInvoices.filterBy('environmentType', request.queryParams.environment)
      };
    }); // ----------- Product Invoice Back-end finish ---------------

    this.passthrough('http://127.0.0.1:10000/devstoreaccount1/**');
    this.passthrough('https://backend.api.nfse.io/**');
    this.passthrough('https://api.nfe.io/v1/**');
    this.passthrough('https://open.nfe.io/v1/**');
    this.passthrough('https://api.nfse.io/**');
    this.passthrough('https://devnfsetable.blob.core.windows.net/**');
    this.passthrough('http://127.0.0.1:10000/devstoreaccount1/**');
    this.passthrough('https://dataprodbatch.blob.core.windows.net/**');
    this.passthrough('https://databatchapi.azurewebsites.net/api/**');
    this.passthrough('https://dfe-prod.service.signalr.net/**');
    this.passthrough('https://dfenotification.azurewebsites.net/**');
    this.passthrough('https://databatchprocesses.azurewebsites.net/**');
    this.passthrough('https://databatchprocesses-staging.azurewebsites.net/**');
    this.passthrough('https://dfeusage.azurewebsites.net/**'); //lead

    this.passthrough('https://webhook.site/7454f61e-630c-430f-80c6-36594e63ed81');
  }
});